.table-transparent td {
    background-color: transparent;
}
.table-transparent th {
    background-color: transparent;
}
@media screen and (max-width: 800px) {
    .container-fluid {
        margin-left: 0;
        padding-left: 0;
        margin-right: 0;
        padding-right: 0;
    } 
    .container-lg {
        margin-left: 0;
        padding-left: 0;
        margin-right: 0;
        padding-right: 0;
    } 
}