:root {
  --workspace-background-one: hsl(0, 0%, 100%);
  --workspace-background-two: hsl(180, 50%, 15%);
  --svg-background: hsl(180, 50%, 10%);
  --workspace-text-color: hsl(180, 10%, 90%);
  --workspace-border-color: hsl(180, 25%, 30%);
  --workspace-shadow: 0 2px 8px 1px rgba(0, 0, 0, 0.3);

  --gap: 16px;
  --border-radius: 8px;
}

#root {
  --navigation-height: 64px;

  .header {
    position: fixed;
    z-index: 3;
    top: 0;
    width: 100%;
    min-height: var(--navigation-height);
    padding: 0 var(--gap);
    border-bottom: 1px solid var(--workspace-border-color);
    background-color: var(--bs-primary);
    box-shadow: var(--workspace-shadow);  
  }

  .content {
    margin-top: var(--navigation-height);
    width: 100%;
    height: calc(100vh - var(--navigation-height));
    max-height: calc(100vh - var(--navigation-height));
    background: var(--workspace-background-one);
  }
}


.image-container {
  background-image: url("./mountains.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 45vh;
  width: 100%;
}

.lower-container {
  width: 100%;
  height: 55vh;
  background-color: #026670;
}
.bottom-container {
  width: 100%;
  height: 0vh;
  background-color: #Edeae5;
}

.login-container {
  position: absolute;
  top: 25vh;
  left: 50%; /* Add this line */
  transform: translateX(-50%); /* Add this line */
  width: 50%;
  background-color: #fefbf6;
  border-radius: 20px;
}
.logo {
  width: 33vw;
  max-width: 500px;
  margin-top: 20px;
}

/* Use a media query to add a breakpoint at 800px: */
@media screen and (max-width: 800px) {
  .login-container {
    width: 100%;
    border-radius: 0;
    border: none;
  }
  .logo {

    width: 60%;
  }
}


.version-bottom {
    position: fixed;
    bottom: var(--gap);
    right: var(--gap);
    z-index: 998;
    color: #777;
    font-size: 0.5em;
    text-align: end;
}

.icon-badge-group .icon-badge-container {
  margin:0;
}
.icon-badge-group {
  display: inline-block;
  margin: 0;
  padding: 0;
  color: var(--bs-navbar-active-color);
}
.icon-badge-group .icon-badge-container:first-child { 
  margin-left:0;
}

.icon-badge-container {
  position:relative;
  display: inline;
  margin: 0;
  padding: 0;
  color: var(--bs-navbar-active-color);
}

.icon-badge-icon {
  position: relative;
}

.icon-badge {
  background-color: red;
  font-size: 10px;
  color: var(--bs-navbar-active-color);
  text-align: center;
  width:15px;
  height:15px;
  border-radius: 35%;
  position: absolute; /* changed */
  top: -5px; /* changed */
  left: 11px; /* changed */
}
.nav-tweak {
  color: var(--bs-navbar-active-color);
}


form.form-tight label.form-label {
  font-size: 0.8em;
  color: #333;
  margin-bottom: 0.1em;
}


