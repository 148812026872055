.stepwizard-step div {
  flex-basis: 100%;
  color: #666;
  font-size: 0.85em;
  word-wrap: break-word;
  overflow-wrap: break-word;
  max-width: 120px;
  margin-inline: auto;
}

.stepwizard-row {
  position: relative;
  display: flex;
}

.stepwizard {
  display: flex;
  width: 100%;
  margin-bottom: 1rem;
  padding-block: 1rem;
  overflow-x: scroll;
}

.stepwizard-step button[disabled] {
  opacity: 1 !important;
  filter: alpha(opacity=100) !important;
}

.stepwizard-row:before {
  top: 14px;
  position: absolute;
  content: '';
  width: 100%;
  height: 1px;
  background-color: #ccc;
  z-index: 0;
}

.stepwizard-step {
  flex: 1;
  min-width: 90px;
  text-align: center;
  position: relative;
  padding: 0 0.5rem;
}

.btn-circle {
  width: 2rem;
  height: 2rem;
  text-align: center;
  padding: 0.375rem 0;
  font-size: 0.875rem;
  line-height: 1.4;
  border-radius: 50%;
  border: 1px solid #ccc !important;
  transition: all 0.2s ease-in-out;
}

.btn-circle i {
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-circle:hover {
  transform: scale(1.1);
}

#dropdown-menu-fixed .dropdown-toggle::after {
  display: none !important;
}

#dropdown-menu-fixed .dropdown-menu  {
  position: fixed !important;
}
