/* xyflow theme files. Delete these to start from our base */

.react-flow {
  /* Custom Variables */
  --xy-theme-selected: #fce181;
  --xy-theme-hover: #fef9c7;
  --xy-theme-edge-hover: black;
  --xy-theme-color-focus: #e8e8e8;

  /* Built-in Variables see https://reactflow.dev/learn/customization/theming */
  --xy-node-border-default: 1px solid #ededed;

  --xy-node-boxshadow-default: 0px 3.54px 4.55px 0px #00000005,
    0px 3.54px 4.55px 0px #0000000d, 0px 0.51px 1.01px 0px #0000001a;

  --xy-node-border-radius-default: 8px;

  --xy-handle-background-color-default: #ffffff;
  --xy-handle-border-color-default: #aaaaaa;

  --xy-edge-label-color-default: #505050;
}

.react-flow.dark {
  --xy-background-color-default: var(--svg-background);
  --xy-node-background-color-default: var(--svg-background);
  --xy-node-border-default: 1px solid var(--workspace-border-color);
  --xy-edge-stroke-default: var(--workspace-border-color);
  --xy-edge-stroke-width-default: 2;
}

.dark .react-flow {
}

/* Customizing Default Theming */

.react-flow__node {
  box-shadow: var(--xy-node-boxshadow-default);
  border-radius: var(--xy-node-border-radius-default);
  background-color: var(--xy-node-background-color-default);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10px;
  font-size: 12px;
  flex-direction: column;
  border: var(--xy-node-border-default);
  color: var(--xy-node-color, var(--xy-node-color-default));
}

.react-flow__node.selectable:focus {
  box-shadow: 0px 0px 0px 1px var(--xy-theme-color-focus);
  border-color: #d9d9d9;
}

.react-flow__node.selectable:focus:active {
  box-shadow: var(--xy-node-boxshadow-default);
}

.react-flow__node.selectable:hover,
.react-flow__node.draggable:hover {
  border-color: var(--xy-theme-hover);
}

.react-flow__node.selectable.selected {
  border-color: var(--xy-theme-selected);
  box-shadow: var(--xy-node-boxshadow-default);
}

.react-flow__node-group {
  background-color: rgba(207, 182, 255, 0.4);
  border-color: #9e86ed;
}

.react-flow__edge.selectable:hover .react-flow__edge-path,
.react-flow__edge.selectable.selected .react-flow__edge-path {
  stroke: var(--xy-theme-edge-hover);
}

.react-flow__handle {
  background-color: var(--xy-handle-background-color-default);
}

.react-flow__edge-path {
  stroke: var(--workspace-border-color);
  stroke-width: 1.5px;
  stroke-opacity: 0.8;
}

.react-flow__handle.connectionindicator:hover {
  pointer-events: all;
  border-color: var(--xy-theme-edge-hover);
  background-color: white;
}

.react-flow__handle.connectionindicator:focus,
.react-flow__handle.connectingfrom,
.react-flow__handle.connectingto {
  border-color: var(--xy-theme-edge-hover);
}

.react-flow__node-resizer {
  border-radius: 0;
  border: none;
}

.react-flow__resize-control.handle {
  background-color: #ffffff;
  border-color: #9e86ed;
  border-radius: 0;
  width: 5px;
  height: 5px;
}

.react-flow__node-project {
  border-radius: 50%;
  height: 120px;
  width: 120px;
  font-family: monospace;
  padding: 0;
  text-align: center;
}

.react-flow__node-candidate {
  border-radius: 50%;
  height: 100px;
  width: 100px;
  font-family: monospace;
  padding: 0;
  text-align: center;
}

.react-flow__node-info,
.react-flow__node-ghost {
  border-radius: 50%;
  height: 30px;
  width: 30px;
  text-align: center;
  border: none;
  padding: 8px;
  transition: box-shadow 0.3s ease, padding 0.3s ease;
}

.react-flow__node-info {
  background-color: var(--workspace-background-two);
  font-family: monospace;
  box-shadow: 0 0 0 0px var(--workspace-background-two),
    0 0 0 1px var(--workspace-border-color);
}

.react-flow__node-info:hover {
  box-shadow: 0 0 0 2px var(--workspace-background-two),
    0 0 0 3px var(--xy-theme-hover);
}

.react-flow__node-ghost {
  background-color: hsl(from var(--workspace-background-two) h s calc(l * 0.7));
  cursor: pointer;
  box-shadow: 0 0 0 0px var(--workspace-background-two),
    0 0 0 1px var(--workspace-background-two);
}

.react-flow__node-ghost:hover {
  box-shadow: 0 0 0 2px
      hsl(from var(--workspace-background-two) h s calc(l * 0.7)),
    0 0 0 3px var(--workspace-background-two);
}

.react-flow__node-info.selectable:focus:active,
.react-flow__node-info.selectable.selected {
  transition: none;
  box-shadow: 0 0 0 2px var(--workspace-background-two),
    0 0 0 3px var(--xy-theme-selected);
}

.react-flow__node-ghost.selectable:focus:active,
.react-flow__node-ghost.selectable.selected {
  transition: none;
  box-shadow: 0 0 0 2px
      hsl(from var(--workspace-background-two) h s calc(l * 0.7)),
    0 0 0 3px var(--xy-theme-selected);
}

.react-flow__node-info svg,
.react-flow__node-ghost svg {
  fill: hsl(from white h s calc(l * 0.8));
  transition: fill 0.3s ease;
}

.react-flow__node-info:hover svg,
.react-flow__node-ghost:hover svg {
  fill: white;
}

.react-flow__node-title {
  position: absolute;
  border: none;
  background: none;
  height: 30px;
  text-align: center;
  box-shadow: none;
  color: var(--workspace-text-color);
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 200;
}

.react-flow__node-subtitle {
  position: absolute;
  border: none;
  background: none;
  height: 30px;
  text-align: center;
  box-shadow: none;
  color: var(--workspace-text-color);
  font-size: 11px;
  font-weight: 200;
  font-style: normal;
}
.react-flow .react-flow__handle {
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
}

.react-flow__node-annotation {
  display: flex;
  max-width: 100px;
  position: absolute;
  align-items: flex-start;
  padding: 0;
  font-size: 8px;
  background-color: unset;
  border: unset;
  border-radius: unset;
  box-shadow: unset;
  color: white;
}

.react-flow__node-annotation .level {
  margin-right: 0.25rem; /* Equivalent to mr-1 */
  line-height: 1.375; /* Equivalent to leading-snug */
}

.react-flow__node-annotation .label {
  line-height: 1.375; /* Equivalent to leading-snug */
}

.react-flow__node-annotation .arrow {
  position: absolute;
  font-size: 1.5rem; /* Equivalent to text-2xl */
}

/* PROJECT */

.project-page-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  touch-action: none;
  background-color: var(--svg-background);

  .project-side-panel {
    position: absolute;
    z-index: 1;
    top: var(--gap);
    bottom: var(--gap);
    left: var(--gap);
    width: 600px;
    max-width: calc(100vw - (var(--gap) * 2));
    display: flex;
    flex-direction: column;
    border-radius: var(--border-radius);
    border: 1px solid var(--workspace-border-color);
    box-shadow: var(--workspace-shadow);
    overflow: hidden;
    transition-duration: 0.2s;
    background-color: var(--workspace-background-one);

    &:not(.open) {
      opacity: 0;
      left: -100px;
      pointer-events: none;
    }

    &.expanded {
      width: calc(100vw - (var(--gap) * 2));
    }

    .project-side-panel-toolbar {
      display: flex;
      justify-content: end;
      gap: var(--gap);
      border-bottom: 1px solid var(--workspace-border-color);
      padding: var(--gap);

      & button {
        width: 25px;
        height: 25px;
        border: 0;
        outline: 0;
        padding: 0;
        background-color: transparent;
        color: var(--bs-link-color);
        transition-duration: 0.2s;

        &:hover {
          transform: scale(1.2);
        }
      }
    }
  }

  .project-svg-panel {
    & g {
      cursor: pointer;
      transition-duration: 0.2s;
      transform-origin: center center;
      background-color: var(--svg-background);
      &:hover {
        transform: scale(1.025);
      }
    }

    .c_edge {
      stroke: var(--workspace-border-color);
      stroke-width: 3px;
      stroke-opacity: 1;
    }

    .c_border {
      stroke: var(--workspace-border-color);
      stroke-width: 3px;
      stroke-opacity: 1;
      fill-opacity: 0;
    }

    .c_image_title {
      fill: var(--workspace-text-color);
      font-size: 18px;
      text-transform: uppercase;
      font-weight: 200;
    }

    .c_background {
      background-color: #ffffff;
      fill-opacity: 1;
      fill: #ffffff;
    }

    .c_image {
      clip-path: inset(0 0 0 0 round 50%);
      z-index: 99;
      background-color: #ffffff;
    }

    .c_main {
      display: flex;
      margin-left: -16px;
      margin-right: -16px;
    }

    .c_menu_item {
      fill: var(--workspace-background-two);
      stroke: var(--workspace-border-color);
      stroke-width: 2;
      stroke-opacity: 1;
    }

    .c_menu_text {
      fill: var(--workspace-text-color);
      font-size: 12px;
    }

    .c_image_tagline {
      fill: var(--workspace-text-color);
      font-size: 12px;
      font-weight: 200;
      font-style: normal;
    }

    .candidates_svg {
      background: #22223b;
    }
  }
}

.readonly-editor .ck.ck-editor__main > .ck-editor__editable {
  border: none !important;
  box-shadow: none !important;
}

.readonly-editor .ck.ck-editor__editable.ck-focused {
  border: none !important;
  box-shadow: none !important;
}

.readonly-editor .ck-editor__editable {
  background: transparent !important;
}
