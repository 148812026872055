.avatar_img {
    max-height: 100px;
    max-width: 100px;
}
.uploading-text {
    position: absolute;
    bottom: 10px;
    left: 10px;
}
.avatar-container {
    border: 1px dotted darkgray;
    display: inline-flex;
    position: relative;
    border-radius: 8px;
    padding: 4px;
}