//$primary: #0d6efd;
$primary: hsl(185, 96%, 22%);
$secondary: hsl(30, 14%, 30%);
$success: #198754;
$info: hsl(181, 70%, 40%);
$warning: #fce181;
$danger: #dc3545;
$light:  #f8f9fa;
$dark: hsl(30, 14%, 20%);;




//custom color to be mapped
$accent : #da6d25;

// this is used to map colors. change accordingly.
$theme-colors: (
  primary: $primary,
  secondary: $secondary,
  success: $success,
  info: $info,
  warning: $warning,
  danger: $danger,
  light: $light,
  dark: $dark,
// add any additional color below
  accent: $accent,
// now bg-accent,btn-accent,etc.. can be called
);

// A form label with a tighter bottom margin and smaller font size

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';
@import "~bootstrap/scss/variables";