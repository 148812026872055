.project-card {
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  flex-direction: column;
  border-width: 1px;
  border-style: solid;
  border-color: #ddd;
  border-radius: var(--bs-border-radius);
  overflow: hidden;
  background-color: var(--color-white);

  .project-card-title {
    font-size: 1.2em;
    text-align: center;
    color: #333 ;
    height: 30px;
  }
  .project-card-status {
    font-size: 0.9em;
    height: 20px;
    text-align: center;
  }
  .project-card-header {
    background-image: url('https://images.unsplash.com/photo-1558591710-4b4a1ae0f04d?q=80&w=3087&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D');
    
    background-size: cover;
    text-align: center;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;

    .project-card-logo {
        text-align: center;
        max-width: 70%;
        max-height: 70%;
    }
  }
  .project-card-footer {
    text-align: end;
    height: 30px;
  }
}