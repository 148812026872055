.dropzone-container {
    border: 1px dotted darkgray;
    display: flex;
    justify-content: center; /* Align content horizontally in the center */
    align-items: center; /* Align content vertically in the center */
    position: relative;
    
    border-radius: 8px;
    padding: 4px;
    width: 100px;
    height: 100px;
}
.dropzone-container img {
    max-width: 90px;
    max-height: 90px;
}