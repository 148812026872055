.candidate-image {
    max-width: 40px;
    max-height: 40px;
}

.avatar-droparea {
    
    width: 100px;
    height: 100px;
    padding: 8px;
    border: 2px dotted lightgray;
    border-radius: 6px;
}
.avatar-droparea img {
    max-height: 80px;
    max-width: 80px;
    display: flex;
}


.batch-panel {
}

.spin {
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
  }