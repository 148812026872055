.symbol {
    &:before {
        display: block;
        width: 100%;
        height: 100%;
        content: "";
        background-color: currentColor;
        -webkit-mask-size: contain;
        -webkit-mask-position: center;
        -webkit-mask-repeat: no-repeat;
    }

    &.close:before {
        -webkit-mask-image: url('data:image/svg+xml,<?xml version="1.0" encoding="UTF-8"?><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><path d="M57.07,50l26.46-26.46c1.95-1.95,1.95-5.12,0-7.07-1.95-1.95-5.12-1.95-7.07,0l-26.46,26.46-26.46-26.46c-1.95-1.95-5.12-1.95-7.07,0-1.95,1.95-1.95,5.12,0,7.07l26.46,26.46-26.46,26.46c-1.95,1.95-1.95,5.12,0,7.07.98.98,2.26,1.46,3.54,1.46s2.56-.49,3.54-1.46l26.46-26.46,26.46,26.46c.98.98,2.26,1.46,3.54,1.46s2.56-.49,3.54-1.46c1.95-1.95,1.95-5.12,0-7.07l-26.46-26.46Z"/></svg>');
    }

    &.expand:before {
        -webkit-mask-image: url('data:image/svg+xml,<?xml version="1.0" encoding="UTF-8"?><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><path d="M89.9,14.02c-.03-.14-.08-.27-.12-.41-.05-.17-.09-.35-.16-.52-.07-.16-.16-.31-.24-.46-.07-.13-.13-.27-.22-.4-.17-.26-.37-.5-.59-.72-.01-.01-.02-.03-.03-.04s-.03-.02-.04-.03c-.22-.22-.46-.42-.72-.59-.12-.08-.26-.14-.39-.21-.16-.09-.31-.18-.47-.25-.16-.07-.33-.1-.5-.15-.15-.04-.29-.1-.44-.13-.31-.06-.62-.09-.93-.09-.02,0-.03,0-.05,0h-25c-2.76,0-5,2.24-5,5s2.24,5,5,5h12.93l-16.46,16.46c-1.95,1.95-1.95,5.12,0,7.07.98.98,2.26,1.46,3.54,1.46s2.56-.49,3.54-1.46l16.46-16.46v12.93c0,2.76,2.24,5,5,5s5-2.24,5-5V15c0-.33-.03-.66-.1-.98Z"/><path d="M43.54,56.46c-1.95-1.95-5.12-1.95-7.07,0l-16.46,16.46v-12.93c0-2.76-2.24-5-5-5s-5,2.24-5,5v25c0,.33.03.66.1.98.03.14.08.28.12.41.05.17.09.35.16.52.07.16.16.31.24.46.07.13.13.27.22.4.17.26.37.5.59.72.01.01.02.03.03.04s.03.02.04.03c.22.22.46.42.72.59.12.08.26.14.39.21.16.09.3.18.47.25.17.07.34.11.51.16.14.04.28.1.42.13.32.06.65.1.98.1h25c2.76,0,5-2.24,5-5s-2.24-5-5-5h-12.93l16.46-16.46c1.95-1.95,1.95-5.12,0-7.07Z"/></svg>');
    }

    &.collapse:before {
        -webkit-mask-image: url('data:image/svg+xml,<?xml version="1.0" encoding="UTF-8"?><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><path d="M44.9,59.02c-.03-.14-.08-.27-.12-.41-.05-.17-.09-.35-.16-.52-.07-.16-.16-.31-.24-.46-.07-.13-.13-.27-.22-.4-.17-.26-.37-.5-.59-.72-.01-.01-.02-.03-.03-.04s-.03-.02-.04-.03c-.22-.22-.46-.42-.72-.59-.12-.08-.26-.14-.39-.21-.16-.09-.31-.18-.47-.25-.16-.07-.33-.1-.5-.15-.15-.04-.29-.1-.44-.13-.31-.06-.62-.09-.93-.09-.02,0-.03,0-.05,0H15c-2.76,0-5,2.24-5,5s2.24,5,5,5h12.93l-16.46,16.46c-1.95,1.95-1.95,5.12,0,7.07.98.98,2.26,1.46,3.54,1.46s2.56-.49,3.54-1.46l16.46-16.46v12.93c0,2.76,2.24,5,5,5s5-2.24,5-5v-25c0-.33-.03-.66-.1-.98Z"/><path d="M85,35h-12.93l16.46-16.46c1.95-1.95,1.95-5.12,0-7.07-1.95-1.95-5.12-1.95-7.07,0l-16.46,16.46v-12.93c0-2.76-2.24-5-5-5s-5,2.24-5,5v25c0,.33.03.66.1.98.03.14.08.28.12.41.05.17.09.35.16.52.07.16.16.31.24.46.07.13.13.27.22.4.17.26.37.5.59.72.01.01.02.03.03.04s.03.02.04.03c.22.22.46.42.72.59.12.08.26.14.39.21.16.09.3.18.47.25.17.07.34.11.51.16.14.04.28.1.42.13.32.06.65.1.98.1h25c2.76,0,5-2.24,5-5s-2.24-5-5-5Z"/></svg>');
    }

    &.zoom-in:before {
        -webkit-mask-image: url('data:image/svg+xml,<?xml version="1.0" encoding="UTF-8"?><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><path d="M80,45h-25v-25c0-2.76-2.24-5-5-5s-5,2.24-5,5v25h-25c-2.76,0-5,2.24-5,5s2.24,5,5,5h25v25c0,2.76,2.24,5,5,5s5-2.24,5-5v-25h25c2.76,0,5-2.24,5-5s-2.24-5-5-5Z"/></svg>');
    }

    &.zoom-out:before {
        -webkit-mask-image: url('data:image/svg+xml,<?xml version="1.0" encoding="UTF-8"?><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><path d="M80,45H20c-2.76,0-5,2.24-5,5s2.24,5,5,5h60c2.76,0,5-2.24,5-5s-2.24-5-5-5Z"/></svg>');
    }
}