.dashboard-section {
    background-color: white;
    padding: 8px;
    border-radius: 8px;
}
/* Use a media query to add a breakpoint at 800px: */
@media screen and (max-width: 800px) {
    .dashboard-section {
        margin-left: 4px;
        padding-left: 4px;
        margin-right: 4px;
        padding-right: 4px;
        border-radius: 0;
    }
  }